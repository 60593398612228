import i18n from "i18next";
import LngDetector from "i18next-browser-languagedetector";

// import TR from './languages/tr.json';
import EN from './languages/en.json';
import PL from './languages/pl.json';

i18n.use(LngDetector).init({
    resources: {
        // TR: { translations: TR },
        EN: { translations: EN },
        PL: { translations: PL }
    },
    detection: {
        order: ["localStorage", "navigator"],
        lookupQuerystring: "lng",
        lookupLocalStorage: "i18nextLng",
        caches: ["localStorage"]
    },
    fallbackLng: "EN",
    whitelist: ['EN', 'PL'],
    nonExplicitWhitelist: true,
    lng: localStorage.getItem("i18nextLng") || "EN",
    debug: true,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
        escapeValue: false,
        formatSeparator: ","
    },
    react: {
        wait: true
    }
});

export default i18n;