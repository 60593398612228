import React from "react";
import PropTypes from 'prop-types'
import Video from "./video";
import { withTranslation } from 'react-i18next';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Main = ({ t }) => {
    const settings = {
        arrows: true,
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <main className="wrapper main--back">
            <Video />
            <div className="video-overlay" />
            <div className="sliderTexts">
                <Slider {...settings}>
                    <div className="sliderItem">
                        <h5>{t('slider_ust_slogan1')}</h5>
                        <h2 className="main--text">{t('slider_ana_slogan1')}</h2>
                        <a href="https://gallus.ai" target="_blank" className="mainBtn" rel="noopener noreferrer">{t('slider_button1')}</a>
                    </div>
                    <div className="sliderItem">
                        <h5>{t('slider_ust_slogan2')}</h5>
                        <h2 className="main--text">{t('slider_ana_slogan2')}</h2>
                        <a href="https://eservisio.com" target="_blank" className="mainBtn" rel="noopener noreferrer">{t('slider_button2')}</a>
                    </div>
                </Slider>
            </div>
        </main>
    );
}

Main.propTypes = {
    t: PropTypes.func.isRequired,
}

export default withTranslation()(Main);
