import React from "react";
import ReactGA from 'react-ga';
import { render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './components/App';
import "./sass/main.scss";
import * as serviceWorker from "./serviceWorker";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import TagManager from 'react-gtm-module';
const tagManagerArgs = {
    gtmId: 'GTM-5KC5KBC'
};

TagManager.initialize(tagManagerArgs);

ReactGA.initialize('UA-156184727-1');
ReactGA.pageview(window.location.pathname + window.location.search);

render((
  <I18nextProvider i18n={i18n}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </I18nextProvider>
), document.getElementById('root'));

serviceWorker.unregister();
