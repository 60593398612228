import React from 'react';
import { Route, Switch } from "react-router-dom";
import Index from "./components/Index/index";
import Contact from "./components/Contact";
import About from "./components/About";
import Project from "./components/Projects";
import Services from "./components/Services";
import Gallus from "./components/Gallus";
import Career from "./components/Career";
import { withTranslation } from 'react-i18next';
import References from './components/References';

class Router extends React.Component {
    render() {
        const { t } = this.props;
        return (
            <Switch>
                <Route exact path="/" component={Index} />
                <Route exact path="/(en|pl)" component={Index} />
                <Route path={"/(en|pl)/" + t("projeler_router")} component={Project} />
                <Route path={"/(en|pl)/" + t("hakkimizda_router")} component={About} />
                <Route path={"/(en|pl)/" + t("references_router")} component={References} />
                <Route path={"/(en|pl)/" + t("servisler_router")} component={Services} />
                <Route path={"/(en|pl)/" + t("servisler_router")} component={Gallus} />
                <Route path={"/(en|pl)/" + t("careers_router")} component={Career} />
                <Route path={"/(en|pl)/" + t("iletisim_router")} component={Contact} />
            </Switch>
        )
    }
}

export default withTranslation()(Router); 