import React from "react";
import { Link } from "react-router-dom";
import NavisioLogo from "../img/navisio-logo.svg";
import LinkedinLogo from "../img/linkedin.svg";
import TwitterLogo from "../img/twitter.svg";
import InstagramLogo from "../img/instagram.svg";
import { withTranslation } from 'react-i18next';
import i18n from '../i18n';
//import Send from "../img/send.svg";

class Footer extends React.Component {
    menuLink = () => {
        window.scrollTo(500, 0);
    }
    render() {
        const { t } = this.props;
        // let hrefLink = '#';
        let link = (i18n.language || window.localStorage.i18nextLng);
        return (
            <footer className="wrapper">
                <div className="row">
                    <div className="columns">
                        <div className="column">
                            <Link to="/" onClick={() => this.menuLink()}>
                                <img src={NavisioLogo} alt="Navisio" />
                            </Link>
                            <div className="socials">
                                <div className="social">
                                    <a href="https://www.linkedin.com/company/navisiotech/" target="_blank" rel="noopener noreferrer">
                                        <img src={LinkedinLogo} alt="Linkedin" />
                                    </a>
                                </div>
                                <div className="social">
                                    <a href="https://twitter.com/NavisioTech" target="_blank" rel="noopener noreferrer">
                                        <img src={TwitterLogo} alt="Twitter" />
                                    </a>
                                </div>
                                <div className="social">
                                    <a href="https://www.instagram.com/navisio_tech/" target="_blank" rel="noopener noreferrer">
                                        <img src={InstagramLogo} alt="Instagram" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <h5>Menu</h5>
                            <ul>
                                <li><Link onClick={() => this.menuLink()} to={"/" + link.toLowerCase() + "/" + t("servisler_router")}>{t("Servisler")}</Link></li>
                                <li><Link onClick={() => this.menuLink()} to={"/" + link.toLowerCase() + "/" + t("hakkimizda_router")}>{t("Hakkımızda")}</Link></li>
                                <li><Link onClick={() => this.menuLink()} to={"/" + link.toLowerCase() + "/" + t("references_router")}>{t("References")} </Link></li>
                                <li><Link onClick={() => this.menuLink()} to={"/" + link.toLowerCase() + "/" + t("projeler_router")}>{t("Projeler")}</Link></li>
                                <li><Link onClick={() => this.menuLink()} to={"/" + link.toLowerCase() + "/" + t("careers_router")}>{t("careers")} </Link></li>
                                <li><Link onClick={() => this.menuLink()} to={"/" + link.toLowerCase() + "/" + t("iletisim_router")}>{t("İletişim")}</Link></li>
                            </ul>
                        </div>
                        <div className="column">
                            <h5>{t("İletişim")}</h5>
                            <address>{t("Adres")}</address>
                            <div className="box">
                                <p>{t("EPosta")} : <a href={"mailto:" + t("EPostaAdress")}>{t("EPostaAdress")}</a></p>
                                <p>{t("Telefon")} : <a href={"tel:" + t("telefonNo")}>{t("telefonNo")}</a></p>
                            </div>

                            {/* <a href={hrefLink} className="showMap">{t("Haritada")}</a> */}
                        </div>
                        <div className="column">
                            {/*<h5>{t("eBulten")}</h5>
                            <p>{t("eBultenAlt")}</p>
                            
                            <div className="emailBox">
                                <input type="text" />
                                <div className="emailBoxIcon">
                                    <img src={Send} alt="Email" placeholder="E-Posta Adresiniz" />
                                </div>
                            </div>
                            */}
                            <div className="ml-form-embed"
                                data-account="1835562:t1a5d6w1d9"
                                data-form="1718842:e0l8s9">
                            </div>
                            <span style={{ marginTop: 10 ,display:'block'}}>© 2019 - {new Date().getFullYear()} Navisio Tech. {t("haklar")}</span>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default withTranslation()(Footer); 