import React from "react";
import parse from 'html-react-parser';
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { Link } from "react-router-dom";

const Cta = ({ t }) => {
  let menuLink = () => {
    window.scrollTo(500, 0);
  }
  let link = (i18n.language || window.localStorage.i18nextLng);
  return(
    <div className="cta">

              <div className="cta--title">
                  {parse(t('ctaTitle'))}
                  {/* <p>{parse(t('ctaSubTitle'))}</p> */}
              </div>
              <div className="cta--description">
                  {t('ctaSubTitle')}
              </div>
              <Link className="cta--link" onClick={() => menuLink()} to={"/" + link.toLowerCase() + "/" + t("contact_router")} >{t("contact")}</Link>
    </div>
  )
}

export default withTranslation()(Cta);