import React, { useState } from "react";
import MetaTags from 'react-meta-tags';
import MapIcon from "../img/map.svg";
import EmailIcon from "../img/email.svg";
import PhoneIcon from "../img/phone.svg";
import TakipIcon from "../img/takip.svg";
import LinkedinLogo from "../img/linkedin.svg";
import TwitterLogo from "../img/twitter.svg";
import InstagramLogo from "../img/instagram.svg";
import PropTypes from 'prop-types'
import parse from 'html-react-parser';
import { withTranslation } from 'react-i18next';
import { API_URL } from "../constants";
import axios from 'axios';
import Swal from 'sweetalert2'
import InputMask from 'react-input-mask';

const Contact = ({ t }) => {
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
    });

    const submitForm = async (event) => {
        event.preventDefault();
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append("to", 'info@navisio.net');
            formData.append(
                "subject",
                `Konu : ${form.subject}`
            );
            formData.append(
                "body",
                `${form.name} <${form.email}><${form.phone}> tarafından gelen mesaj. Mesaj : ${form.message}`
            );
            const { data } = await axios.post(API_URL + 'mail', formData);
            Swal.fire({
                title: t('basarili'),
                icon: 'success',
                confirmButtonText: 'OK'
            })
            if (data.success === true) {
                setForm({
                    name: "",
                    email: "",
                    phone: "",
                    subject: "",
                    message: "",
                });
                Swal.fire({
                    title: t('basarili'),
                    text: t('mailUlasti'),
                    icon: 'success',
                    confirmButtonText: 'OK'
                })
            } else {
                Swal.fire({
                    title: t('hata'),
                    icon: 'error',
                    confirmButtonText: 'OK'
                })
            }
            setLoading(false);
        }
        catch (e) {
            setLoading(false);
            Swal.fire({
                title: t('hata'),
                icon: 'error',
                confirmButtonText: 'OK'
            })
        }
    }

    return (
        <>
            <MetaTags>
                <title>Navisio Tech. | {t('iletisimMetaTitle')}</title>
                <meta name="keywords" content={t('iletisimMetaKeyword')} />
                <meta name="description" content={t('iletisimMetaDescription')} />
                <meta name="author" content="navisio<info@navisio.net>" />
                <meta name="owner" content="Navisio" />
                <meta name="copyright" content="(c) 2020" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={t('iletisimMetaTitle')} />
                <meta property="og:description" content={t('iletisimMetaDescription')} />
                <meta name="twitter:card" content="summary" />
            </MetaTags>
            <div className="signup center">
                <div className="title">
                    <p>{t('iletisimBaslik')}</p>
                    <h5>{parse(t('iletisimAltBaslik'))}</h5>
                </div>
                <div className="description">
                    <p>{t('iletisimAciklama')}</p>
                </div>
                <div className="items">
                    <div className="item">
                        <div className="iconIletisim">
                            <img src={MapIcon} alt="Map" />
                        </div>
                        <div className="text">
                            <h5>{t('biziZiyaretEdin')}</h5>
                            <p>{t('Adres')}</p>
                        </div>
                    </div>
                    <div className="item">
                        <div className="iconIletisim">
                            <img src={PhoneIcon} alt="Map" />
                        </div>
                        <div className="text">
                            <h5>{t('biziArayin')}</h5>
                            <a href={"tel:" + t("telefonNo")}>{t("telefonNo")}</a>
                        </div>
                    </div>
                    <div className="item">
                        <div className="iconIletisim">
                            <img src={EmailIcon} alt="Map" />
                        </div>
                        <div className="text">
                            <h5>{t('bizeYazin')}</h5>
                            <a href={"mailto:" + t("EPostaAdress")}>{t("EPostaAdress")}</a>
                        </div>
                    </div>
                    <div className="item">
                        <div className="iconIletisim">
                            <img src={TakipIcon} alt="Map" />
                        </div>
                        <div className="text">
                            <h5>{t('biziTakipEt')}</h5>
                            <ul>
                                <li>
                                    <a href="https://www.linkedin.com/company/navisiotech/" target="_blank" rel="noopener noreferrer">
                                        <img src={LinkedinLogo} alt="Linkedin" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/NavisioTech" target="_blank" rel="noopener noreferrer">
                                        <img src={TwitterLogo} alt="Twitter" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/navisio_tech/" target="_blank" rel="noopener noreferrer">
                                        <img src={InstagramLogo} alt="Instagram" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <section id="iletisim">
                    <div className="flexSection">
                        <div className="left"></div>
                        <div className="right">
                            <div className="title">
                                <p>{t('projeOneri')}</p>
                                <h5>{t('bizeHemenYazin')}</h5>
                            </div>
                            <form onSubmit={submitForm}>
                                <div className="col-12">
                                    <div className="col-6">
                                        <input
                                            type="text"
                                            name="name"
                                            value={form.name}
                                            onChange={e => setForm({ ...form, name: e.target.value })}
                                            placeholder={t('isim')}
                                            required />
                                    </div>
                                    <div className="col-6">
                                        <input
                                            type="email"
                                            name="email"
                                            value={form.email}
                                            onChange={e => setForm({ ...form, email: e.target.value })}
                                            placeholder={t('email')}
                                            required />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="col-6">
                                        <InputMask
                                            required
                                            value={form.phone}
                                            onChange={e => setForm({ ...form, phone: e.target.value })}
                                            placeholder={t('telefon')}
                                            mask="+99 (999) 999 9999"
                                            maskChar=" " />
                                    </div>
                                    <div className="col-6">
                                        <input
                                            type="text"
                                            name="subject"
                                            value={form.subject}
                                            onChange={e => setForm({ ...form, subject: e.target.value })}
                                            placeholder={t('konu')}
                                            required />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <textarea
                                        name="message"
                                        value={form.message}
                                        onChange={e => setForm({ ...form, message: e.target.value })}
                                        placeholder={t('mesaj')}
                                        required>
                                    </textarea>
                                </div>
                                <button type="submit">{loading ? t('lutfenBekleyiniz') : t('gonder')}</button>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

Contact.propTypes = {
    t: PropTypes.func.isRequired,
}

export default withTranslation()(Contact); 