import React, { useEffect, useState, useRef } from "react";
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next';
import { API_URL } from "../constants";
import axios from 'axios';
import Swal from 'sweetalert2'
import i18n from '../i18n';
import parse from 'html-react-parser';
const Jobs = require('../jobs.json');

const Career = ({ t }) => {
    let _lang = i18n.language.toLocaleLowerCase();
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState('');
    const [form, setForm] = useState({
        applyingJobID: 0,
        applyingJobTitle: "",
        name: "",
        email: "",
        message: ""
    });
    const [applyStatus, setApplyStatus] = useState(false);
    const [modalStyle, setModalStyle] = useState({
        visibility: "hidden",
        opacity: 0
    })
    let cvRef = useRef(null)
    useEffect(() => {
        setForm({
            applyingJobID: 0,
            applyingJobTitle: "",
            name: "",
            email: "",
            message: ""
        });
        setApplyStatus(false);
        setModalStyle({
            visibility: "hidden",
            opacity: 0
        });
    }, []);

    function toggleJob(e) {
        let style = e.target.parentNode.parentNode.querySelector(
            ".job__details"
        ).style;
        if (style.opacity === "1") {
            style.maxHeight = 0;
            style.opacity = 0;
            style.visibility = "hidden";
        } else {
            style.maxHeight = "9999px";
            style.opacity = 1;
            style.visibility = "visible";
        }
    }
    function openForm(id, title) {
        setForm({
            ...form,
            applyingJobID: id,
            applyingJobTitle: title
        });
        setModalStyle({
            ...modalStyle,
            visibility: "visible",
            opacity: 1
        });
    }
    function closeForm() {
        setModalStyle({
            ...modalStyle,
            visibility: "hidden",
            opacity: 0
        });
    }
    const submitForm = async (event) => {
        event.preventDefault();
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append("to", 'hr@navisio.net');
            formData.append(
                "subject",
                `Başvuru: ${form.name} - ${
                form.applyingJobTitle
                }`
            );
            formData.append(
                "body",
                `${form.name} <${form.email}> tarafından ${
                form.applyingJobID
                } numaralı ${
                form.applyingJobTitle
                } pozisyonundaki ilana başvurulmuştur. CV ektedir.Mesaj : ${form.message}`
            );

            if (files !== null) {
                formData.append("attachments", files);
            }

            const { data } = await axios.post(API_URL + 'mail', formData);
            if (data.success === true) {
                setForm({
                    applyingJobID: 0,
                    applyingJobTitle: "",
                    name: "",
                    email: "",
                    message: ""
                });
                setFiles(null);
                Swal.fire({
                    title: t('basarili'),
                    text: t('mailUlasti'),
                    icon: 'success',
                    confirmButtonText: 'OK'
                })
            } else {
                Swal.fire({
                    title: t('hata'),
                    icon: 'error',
                    confirmButtonText: 'OK'
                })
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            Swal.fire({
                title: t('hata'),
                icon: 'error',
                confirmButtonText: 'OK'
            })
        }
    }

    const fileSet = (e) => {
        if (e.target.files.length > 0) {
            setFiles(e.target.files[0])
        }
    }

    return (
        <>
            <MetaTags>
                <title>Navisio Tech. | {t('careersMetaTitle')}</title>
                <meta name="keywords" content={t('careersMetaKeyword')} />
                <meta name="description" content={t('careersMetaDescription')} />
                <meta name="author" content="navisio<info@navisio.eu>" />
                <meta name="owner" content="Navisio" />
                <meta name="copyright" content="(c) 2020" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={t('careersMetaTitle')} />
                <meta property="og:description" content={t('careersMetaDescription')} />
                <meta name="twitter:card" content="summary" />
            </MetaTags>
            <div className="widgetBox">
                <div className="flexSection">
                    <div className="left">
                        <div className="title">
                            <p>{t('kariyerBaslik')}</p>
                            <h5>{t('kariyerAltBaslik')}</h5>
                        </div>
                        <div className="description">
                            <p>{t('kariyerAciklama')}</p>
                        </div>
                    </div>
                    <div className="right insanKaynaklari"></div>
                </div>
            </div>
            <div className="jobs">
                <h1 className="jobs-title">{t('acikPozisyonlar')}</h1>
                <div className="row">
                    <ul className="jobs-list">
                        {Jobs.map((item, index) => (
                            <li className="job" key={index}>
                                <div className="job__panel">
                                    <h2 className="job__title">{item[_lang].jobTitle}</h2>
                                    <button  className="job__button" onClick={toggleJob} >
                                        + {t('jobDetails')}
                                </button>
                                </div>
                                <div className="job__details">
                                    <b>{parse(t('jobIntroduction'))}</b>
                                    <div>
                                        {item[_lang].jobIntroduction.length > 0 && <ul>
                                            {item[_lang].jobIntroduction.map((intItem, intKey) => (
                                                <li key={intKey}>{intItem}</li>
                                            ))}
                                        </ul>}
                                    </div>
                                    <b>{parse(t('jobDescription'))}</b>
                                    <div>
                                        {item[_lang].jobDescription.length > 0 && <ul>
                                            {item[_lang].jobDescription.map((desItem, desKey) => (
                                                <li key={desKey}>{desItem}</li>
                                            ))}
                                        </ul>}
                                    </div>

                                    <b>{parse(t('jobResponsibilities'))}</b>
                                    <div>
                                        {item[_lang].jobResponsibilities.length > 0 && <ul>
                                            {item[_lang].jobResponsibilities.map((resItem, resKey) => (
                                                <li key={resKey}>{resItem}</li>
                                            ))}
                                        </ul>}
                                    </div>
                                    <b>{parse(t('jobExpectations'))}</b>
                                    <div>
                                        {item[_lang].jobExpectations.length > 0 && <ul>
                                            {item[_lang].jobExpectations.map((expItem, expKey) => (
                                                <li key={expKey}>{expItem}</li>
                                            ))}
                                        </ul>}
                                    </div>
                                    <b>{parse(t('lokasyon'))}: {parse(item[_lang].location)}</b>
                                    <button
                                        className="job-button"
                                        onClick={() =>
                                            openForm(item[_lang].id, item[_lang].jobTitle)
                                        }
                                    >
                                        {t('basvur')}
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div
                    className="overlay"
                    onClick={() => closeForm()}
                    style={modalStyle}
                >
                    <div onClick={e => e.stopPropagation()} className="popup">
                        <div className="close" onClick={() => closeForm()}>
                            &times;
                        </div>
                        <div className="popupArea">
                            <h1>{t('basvur')}</h1>
                            <div>
                                {applyStatus === "success" ? <p className="result successResult">{t('basarili')}</p> : applyStatus === false ? "" : <p className="result errorResult">{t('hata')}</p>}
                            </div>
                            <form className="apply-form" onSubmit={submitForm}>
                                <input
                                    type="text"
                                    className="hidden"
                                    name="jobid"
                                    value={form.applyingJobID}
                                    disabled
                                />
                                <input
                                    type="text"
                                    className="hidden"
                                    name="jobtitle"
                                    value={form.applyingJobTitle}
                                    disabled
                                />
                                <div className="col-12">
                                    <div className="col-6">
                                        <input
                                            className="apply-input"
                                            type="text"
                                            name="name"
                                            placeholder={t('isim')}
                                            value={form.name}
                                            onChange={e => setForm({
                                                ...form,
                                                name: e.target.value
                                            })
                                            }
                                            required
                                        />
                                    </div>
                                    <div className="col-6">
                                        <input
                                            className="apply-input"
                                            type="email"
                                            name="email"
                                            placeholder={t('email')}
                                            value={form.email}
                                            onChange={e => setForm({
                                                ...form,
                                                email: e.target.value
                                            })
                                            }
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <textarea
                                        className="apply-input"
                                        name="message"
                                        placeholder={t('mesaj')}
                                        value={form.message}
                                        onChange={e => setForm({
                                            ...form,
                                            message: e.target.value
                                        })
                                        }
                                        required>
                                        {form.message}
                                    </textarea>
                                </div>
                                {files !== null && <p>{files.name}</p>}
                                <label className="fileUpload" onClick={() => cvRef.click()}>{t('dosyaEkle')}</label>
                                <input type="file" name="cv" onChange={(e) => fileSet(e)} ref={input => cvRef = input} />
                                <input
                                    className="job-button"
                                    type="submit"
                                    value={loading ? t('lutfenBekleyiniz') : t('basvur')}
                                />
                            </form>

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

Career.propTypes = {
    t: PropTypes.func.isRequired,
}

export default withTranslation()(Career); 