import React from "react";
import PropTypes from 'prop-types'
import { Link } from "react-router-dom";
import Slider from "react-slick";
import parse from 'html-react-parser';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ServiceImg from "../../img/navisio-service.svg";
import GallusImg from "../../img/gallusBanner.svg";
import WebIcon from "../../img/web.svg";
import UiIcon from "../../img/ui.svg";
import TeamIcon from "../../img/team.svg";
import PhoneIcon from "../../img/smartphone.svg";
import DesktopIcon from "../../img/desktop.svg";
import CloudIcon from "../../img/cloud-computing.svg";
import TeamworkIcon from "../../img/teamwork.svg";
import Computercon from "../../img/computer.svg";
import SketchIcon from "../../img/sketch.svg";
import DataIcon from "../../img/data.svg";
// import DanismanImg from "../../img/danisman.png";
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';

const Services = ({ t }) => {
    let menuLink = () => {
        window.scrollTo(500, 0);
    }
    let link = (i18n.language || window.localStorage.i18nextLng);
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div>
            <section className="wrapper services sec-service--back">
                <div className="row sec-service">
                    <div className="item itemFirst">
                        <h3 className="shadowText">NAVISIO</h3>
                        <div className="left">
                            <div className="title">
                                <p>{t('neler_yapiyoruz')}</p>
                                <h5>{t('neler_yapiyoruz_alt')}</h5>
                            </div>
                            <div className="description">
                                <p>{t('neler_yapiyoruz_aciklama')}</p>
                            </div>
                            <div className="boxes">
                                <div className="box">
                                    <div className="img">
                                        <img src={WebIcon} alt="Web Geliştirme" />
                                    </div>
                                    <div className="body">
                                        <div className="title">
                                            <h5>{t('servis1baslik')}</h5>
                                        </div>
                                        <div className="description">
                                            <p>{t('servis1aciklama')}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="img">
                                        <img src={UiIcon} alt="UI/UX Tasarımı" />
                                    </div>
                                    <div className="body">
                                        <div className="title">
                                            <h5>{t('servis2baslik')}</h5>
                                        </div>
                                        <div className="description">
                                            <p>{t('servis2aciklama')}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="img">
                                        <img src={PhoneIcon} alt="Mobil Uygulama" />
                                    </div>
                                    <div className="body">
                                        <div className="title">
                                            <h5>{t('servis3baslik')}</h5>
                                        </div>
                                        <div className="description">
                                            <p>{t('servis3aciklama')}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="img">
                                        <img src={TeamIcon} alt="Kurumsal Yazılım" />
                                    </div>
                                    <div className="body">
                                        <div className="title">
                                            <h5>{t('servis4baslik')}</h5>
                                        </div>
                                        <div className="description">
                                            <p>{t('servis4aciklama')}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="img">
                                        <img src={DesktopIcon} alt="Masaüstü Yazılım" />
                                    </div>
                                    <div className="body">
                                        <div className="title">
                                            <h5>{t('servis5baslik')}</h5>
                                        </div>
                                        <div className="description">
                                            <p>{t('servis5aciklama')}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="img">
                                        <img src={CloudIcon} alt="Bulut Tabanlı Yazılımlar" />
                                    </div>
                                    <div className="body">
                                        <div className="title">
                                            <h5>{t('servis6baslik')}</h5>
                                        </div>
                                        <div className="description">
                                            <p>{t('servis6aciklama')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Link onClick={() => menuLink()} to={"/" + link.toLowerCase() + "/" + t("servisler_router")} className="btnAll">{t("servislerimiz")}</Link>
                        </div>
                        <div className="right">
                            <img src={ServiceImg} alt="Navisio" />
                        </div>
                    </div>
                </div>
            </section>
            <div className="clearfix"></div>
            <section className="wrapper services second sec-service--back">
                <div className="row sec-service">
                    <div className="title hiddenDesktop">
                        <p>{t('proje_baslik')}</p>
                        <h5>{t('proje_aciklama')}</h5>
                    </div>
                    <div className="item">
                        <h3 className="shadowText">{t('projeler')}</h3>
                        <div className="left">
                            <div className="title">
                                <p>{t('proje_baslik')}</p>
                                <h5>{t('proje_aciklama')}</h5>
                            </div>
                            <div className="boxes service_boxes">
                                <div className="box active">
                                    <div className="body">
                                        <div className="title">
                                            <h5><a href="https://gallus.ai" rel="noopener noreferrer" target="_blank"> Gallus InsureTech</a></h5>
                                        </div>
                                        <div className="description">
                                            <p>{t('proje1aciklama')}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="box hidden">
                                    <div className="body">
                                        <div className="title">
                                            <h5><a href="https://eservisio.com" rel="noopener noreferrer" target="_blank"> E-Servis.io</a></h5>
                                        </div>
                                        <div className="description">
                                            <p>{t('proje2aciklama')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <img src={GallusImg} alt="Gallus" />
                        </div>
                    </div>
                </div>
            </section>
            <div className="widgetBox">
                <div className="flexSection">
                    <div className="left">
                        <div className="title">
                            <p>{parse(t('referencesTitle'))}</p>
                            <h5>{parse(t('referencesSubTitle'))}</h5>
                        </div>
                        <div className="description">
                            <p>{t('referencesTitle')}</p>
                        </div>
                        <Link onClick={() => menuLink()} to={"/" + link.toLowerCase() + "/" + t("references_router")} className="btnAll">{t("references")}</Link>
                    </div>
                    <div className="right"></div>
                </div>
            </div>
            <section className="plain">
                <div className="row">
                    <div className="title">
                        <p>{t('projeSureciBaslik')}</p>
                        <h5>{t('projeSureciAciklama')}</h5>
                    </div>
                    <div className="items">
                        <Slider {...settings}>
                            <div className="item">
                                <div className="detail">
                                    <div className="top">
                                        <img src={TeamworkIcon} alt="Navisio" />
                                        <h5>{t('surec1Baslik')}</h5>
                                    </div>
                                    <div className="body">
                                        <p>{t('surec1Aciklama')}</p>
                                    </div>
                                </div>
                                <div className="footer">
                                    <p>01</p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="detail">
                                    <div className="top">
                                        <img src={SketchIcon} alt="Navisio" />
                                        <h5>{t('surec2Baslik')}</h5>
                                    </div>
                                    <div className="body">
                                        <p>{t('surec2Aciklama')}</p>
                                    </div>
                                </div>
                                <div className="footer">
                                    <p>02</p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="detail">
                                    <div className="top">
                                        <img src={Computercon} alt="Navisio" />
                                        <h5>{t('surec3Baslik')}</h5>
                                    </div>
                                    <div className="body">
                                        <p>{t('surec3Aciklama')}</p>
                                    </div>
                                </div>
                                <div className="footer">
                                    <p>03</p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="detail">
                                    <div className="top">
                                        <img src={DataIcon} alt="Navisio" />
                                        <h5>{t('surec4Baslik')}</h5>
                                    </div>
                                    <div className="body">
                                        <p>{t('surec4Aciklama')}</p>
                                    </div>
                                </div>
                                <div className="footer">
                                    <p>04</p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="detail">
                                    <div className="top">
                                        <img src={TeamworkIcon} alt="Navisio" />
                                        <h5>{t('surec5Baslik')}</h5>
                                    </div>
                                    <div className="body">
                                        <p>{t('surec5Aciklama')}</p>
                                    </div>
                                </div>
                                <div className="footer">
                                    <p>05</p>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </section>
            {/* <section className="wrapper services danismanlik sec-service--back">
                <div className="row sec-service">
                    <div className="item">
                        <h3 className="shadowText">{parse(t('disKaynakGolgeYazi'))}</h3>
                        <div className="left">
                            <div className="title">
                                <p>{t('disKaynakBaslik')}</p>
                                <h5>{t('disKaynakAltBaslik')}</h5>
                            </div>
                            <div className="description">
                                <p>{t('disKaynakAciklama')}</p>
                            </div>
                            <Link onClick={() => menuLink()} to={"/" + link.toLowerCase() + "/" + t("iletisim_router")} className="btnAll">{t("İletişim")}</Link>
                        </div>
                        <div className="right">
                            
                            <img src={DanismanImg} alt={t('danismanlik')} />
                        </div>
                    </div>
                </div>
            </section> */}

        </div>
    );
}

Services.propTypes = {
    t: PropTypes.func.isRequired,
}

export default withTranslation()(Services); 
