import React, { Component, Fragment } from "react";
import Header from "./Header";
import ScrollTopButton from "./ScrollTopButton";
import CookiePolicy from "./CookiePolicy";
import Router from '../router';
import Footer from "./Footer";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showScrollBtn: false
    };
  }
  handleScroll() {
    if (window.pageYOffset > 50) {
      this.setState({ showScrollBtn: true });
    } else {
      this.setState({ showScrollBtn: false });
    }
  }
  componentDidMount() {
    window.addEventListener("scroll", () => this.handleScroll());
  }

  render() {
    return (
      <Fragment>
        <Header />
        <Router />
        <ScrollTopButton show={this.state.showScrollBtn} />
        <CookiePolicy />
        < Footer />
      </Fragment>
    );
  }
}


export default App;

