import React from "react";
import { withTranslation } from 'react-i18next';

class CookiePolicy extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            isActive:true
        }
        this.closeCookies=this.closeCookies.bind(this);
    }
    closeCookies(){
        localStorage.setItem("cookie",1);
        this.setState({isActive:false});
    }
    render() {
        const { t } = this.props;
        const isAccepted= localStorage.getItem("cookie");
        if(isAccepted==="1" || this.state.isActive!==true) return null;
            return (
                <div className="cookie-policy">
                    <p className="cookie-policy__paragraph">
                        {t('cookiePolicy')}
                    </p>
                    <button
                        onClick={() => this.closeCookies()}
                        className="cookie-policy__button"
                    >
                        <i className="fas fa-check" />
                        {t('cookiePolicyAcceptBtn')}
                    </button>
                </div>
            );
    }
}

export default withTranslation()(CookiePolicy); 