
import React from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next';
import GallusLogo from "../img/logo.svg";
import EServisioLogo from "../img/servis_logo.svg";
import TecaLogo from "../img/teca-logo.png";

const Projects = ({ t }) => {
    return (
        <>
            <MetaTags>
                <title>Navisio Tech. | {t('projelerMetaTitle')}</title>
                <meta name="keywords" content={t('projelerMetaKeyword')} />
                <meta name="description" content={t('projelerMetaDescription')} />
                <meta name="author" content="navisio<info@navisio.net>" />
                <meta name="owner" content="Navisio" />
                <meta name="copyright" content="(c) 2020" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={t('projelerMetaTitle')} />
                <meta property="og:description" content={t('projelerMetaDescription')} />
                <meta name="twitter:card" content="summary" />
            </MetaTags>
            <div className="project">
                <div className="row">
                    <div className="block">
                        <div className="project-image">
                            <img src={TecaLogo} alt="Gallus.ai" />
                        </div>

                        <div className="project-text">
                            <p>{t("teca")}</p>
                                <a href="https://teca.com.tr" target="_blank" rel="noopener noreferrer">
                                <p>teca.com.tr</p>
                            </a>
                        </div>
                    </div>
                    <div className="block">
                        <div className="project-image">
                            <img src={GallusLogo} alt="Gallus.ai" />
                        </div>
                        <div className="project-text">
                            <p>{t("gallus")}</p>
                                <a href="https://gallus.ai" target="_blank" rel="noopener noreferrer">
                                    <p>gallus.ai</p>
                                </a>
                        </div>
                    </div>
                    <div className="block">
                        <div className="project-image">
                            <img src={EServisioLogo} alt="E-Servis.io" />
                        </div>
                        <div className="project-text">
                            <p>{t("eservisio")}</p>
                                <a href="https://eservisio.com" target="_blank" rel="noopener noreferrer">
                                    <p>eservisio.com</p>
                                </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

Projects.propTypes = {
    t: PropTypes.func.isRequired,
}

export default withTranslation()(Projects); 