import React from "react";

class ScrollTopButton extends React.Component {
    constructor(props) {
        super(props);
        this.buttonRef = React.createRef();
    }
    handleScrollBtn() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    componentDidUpdate() {
        if (this.props.show) {
            this.buttonRef.current.style.visibility = "visible";
            this.buttonRef.current.style.opacity = 1;
        } else {
            this.buttonRef.current.style.visibility = "hidden";
            this.buttonRef.current.style.opacity = 0;
        }
    }
    render() {
        return (
            <button
                ref={this.buttonRef}
                onClick={this.handleScrollBtn}
                className="scroll-top-btn"
            >
                <i className="fas fa-chevron-up" />
            </button>
        );
    }
}

export default ScrollTopButton;
