
import React from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import parse from 'html-react-parser';
import { withTranslation } from 'react-i18next';

const About = ({ t }) => {
    return (
        <>
            <MetaTags>
                <title>Navisio Tech. | {t('hakkimizdaMetaTitle')}</title>
                <meta name="keywords" content={t('hakkimizdaMetaKeyword')} />
                <meta name="description" content={t('hakkimizdaMetaDescription')} />
                <meta name="author" content="navisio<info@navisio.net>" />
                <meta name="owner" content="Navisio" />
                <meta name="copyright" content="(c) 2020" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={t('hakkimizdaMetaTitle')} />
                <meta property="og:description" content={t('hakkimizdaMetaDescription')} />
                <meta name="twitter:card" content="summary" />
            </MetaTags>
            <div className="about">
                <div className="row">
                    {parse(t('hakkimizdaYaziHtml'))}
                </div>
            </div>
        </>
    );
};

About.propTypes = {
    t: PropTypes.func.isRequired,
}

export default withTranslation()(About); 