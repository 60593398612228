import React, { useState } from "react";
import { Link } from "react-router-dom";
import Icon from "../img/worlwide.svg";
import GallusLogo from "../img/logo.svg";
import EServisioLogo from "../img/servis_logo.svg";

import { withTranslation } from 'react-i18next';
import i18n from '../i18n';

function Menu({ t }) {
    const [menuType, setMenuType] = useState('gallus');
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        var sefLink = window.location.pathname;
        document.documentElement.lang = lng;
        if (sefLink !== null && sefLink !== "/") {
            window.location.href = "/" + lng.toLowerCase() + "/" + t(sefLink.split('/')[sefLink.split('/').length - 1] + "_router");
        }
    }
    const menuLink = () => {
        window.scrollTo(500, 0);
    }
    let link = (i18n.language || window.localStorage.i18nextLng);
    return (
        <div className="header--menu">
            <ul>
                <li><Link to={"/" + link.toLowerCase() + "/" + t("servisler_router")} onClick={() => menuLink()}>{t("Servisler")}</Link></li>
                <li><Link onClick={() => menuLink()} to={"/" + link.toLowerCase() + "/" + t("hakkimizda_router")}>{t("Hakkımızda")}</Link></li>
                <li><Link onClick={() => menuLink()} to={"/" + link.toLowerCase() + "/" + t("references_router")}>{t("References")} </Link></li>
                <li><Link onClick={() => menuLink()} to={"/" + link.toLowerCase() + "/" + t("projeler_router")}><span>{t("Projeler")}</span></Link>
                    <div className="megaMenu">
                        <div className="flexSection">
                            <div className="left">
                                <ul>
                                    <li className={menuType === 'gallus' ? 'active' : ''}><a href={'https://gallus.ai'} target="_blank" rel="noopener noreferrer" onMouseOver={() => setMenuType('gallus')}>Gallus Insuretech</a></li>
                                    <li className={menuType === 'eservisio' ? 'active' : ''}><a href={'https://eservisio.com'} target="_blank" rel="noopener noreferrer" onMouseOver={() => setMenuType('eservisio')}>E-Servis.io</a></li>
                                </ul>
                            </div>
                            <div className="right">
                                <div className="megaLogo">
                                    {menuType === 'gallus' ? <>
                                        <img src={GallusLogo} alt="Gallus.ai" />
                                        <p>{t("gallusMenu")}</p>
                                    </> : <>
                                            <img src={EServisioLogo} alt="E-Servis.io" />
                                            <p>{t("eservisioMenu")}</p>
                                        </>}
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li><Link onClick={() => menuLink()} to={"/" + link.toLowerCase() + "/" + t("careers_router")}>{t("careers")} </Link></li>
                <li><Link onClick={() => menuLink()} to={"/" + link.toLowerCase() + "/" + t("iletisim_router")}>{t("İletişim")}</Link></li>
            </ul>
            <div className="languages">
                <div className="active">
                    <img src={Icon} alt="Language" />
                    <p>{link}</p>
                </div>
                <ul>
                    <li>
                        {/* {
                            link === "TR" ? <button onClick={() => changeLanguage('EN')}>EN</button> : <button onClick={() => changeLanguage('TR')}>TR</button>
                        } */}
                                {/* <button onClick={() => changeLanguage('TR')}>TR</button> <br/> */}
                                <button onClick={() => changeLanguage('EN')}>EN</button> <br/>
                                <button onClick={() => changeLanguage('PL')}>PL</button>
                    </li>
                    {  /*<li className="lineLanguage"></li>*/}
                </ul>
            </div>
        </div>

    );
}

export default withTranslation()(Menu);
