
import React from 'react';
import videoMp4 from '../../video/navisio.mp4';
import videoOgv from '../../video/navisio.ogv';
import videoWebM from '../../video/navisio.webm';
import videoPoster from '../../video/navisio.jpg';

const Video = () => {
    return (
        <div className="video__wrapper">
            <video className="main--video" muted poster={videoPoster} loop autoPlay>
                <source src={videoMp4} type="video/mp4" />
                <source src={videoWebM} type="video/webm" />
                <source src={videoOgv} type="video/ogv" />
            </video>
        </div>
    );
};
export default Video;
