import React from "react";
import { Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { withTranslation } from 'react-i18next';
import NavisioLogo from "../img/navisio-logo.svg";
import Menu from "./Menu";
import MobileMenu from "./MobileMenu";

class Header extends React.Component {
    state = {
        menuActive: false
    }
    menuToggle=()=>{
        this.setState({ menuActive: !this.state.menuActive });
    }
    menuLink = () => {
        window.scrollTo(500, 0);
    }
    render() {
        const { t } = this.props;
        return (
            <>
            <MetaTags>
                <title>Navisio Tech. | {t('referencesMetaTitle')}</title>
                <meta name="keywords" content={t('referencesMetaKeyword')} />
                <meta name="description" content={t('referencesMetaDescription')} />
                <meta name="author" content="navisio<info@navisio.eu>" />
                <meta name="owner" content="Navisio" />
                <meta name="copyright" content="(c) 2020" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={t('referencesMetaTitle')} />
                <meta property="og:description" content={t('referencesMetaDescription')} />
                <meta name="twitter:card" content="summary" />
                <meta name="yandex-verification" content="5029a6989f669e45" />
            </MetaTags>
                <div className="header--wrapper">
                    <header className="row header">
                        <Menu />
                        <div className="header--left">
                            <div className="header--logo">
                                <Link to="/" onClick={() => this.menuLink()}>
                                    <img src={NavisioLogo} alt="Navisio" />
                                </Link>
                            </div>
                        </div>
                        <div className="header--right" onClick={() => this.menuToggle()}>
                            <button>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" className={this.state.menuActive ? 'activeMenu' : ''}>
                                    <g transform="translate(-330 -23)">
                                        <rect id="menuIcon1" width="20" height="3" rx="1.5" transform="translate(330 23)" />
                                        <rect id="menuIcon2" width="20" height="3" rx="1.5" transform="translate(330 29)" />
                                        <rect id="menuIcon3" width="13" height="3" rx="1.5" transform="translate(337 35)" />
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </header>
                </div>
                <MobileMenu  menuToggle={this.menuToggle} active={this.state.menuActive} />
                <div className="headerArea"  style={{ height: 90 }}></div>
            </>
        );
    }
}

export default withTranslation()(Header);
