
import React from 'react';
import { withTranslation } from 'react-i18next';

const Gallus = ({t}) => {
    return (

        <div className="servislerimiz">
            {t("Introduction")}
        </div>
    );
};

export default withTranslation()(Gallus);
