import React from "react";
import { Link } from "react-router-dom";
import Icon from "../img/worlwide.svg";
import { withTranslation } from 'react-i18next';
import i18n from '../i18n';

function MobileMenu({ t, active, menuToggle }) {

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        var sefLink = window.location.pathname;
        document.documentElement.lang = lng;
        if (sefLink !== null && sefLink !== "/") {
            window.location.href = "/" + lng.toLowerCase() + "/" + t(sefLink.split('/')[sefLink.split('/').length - 1] + "_router");
        }
        window.scrollTo(500, 0);
    }
    const menuLink = () => {
        window.scrollTo(500, 0);
        menuToggle();
    }
    let link = (i18n.language || window.localStorage.i18nextLng);
    return (
        <div className={"mobileMenu " + (active && "menuActive")}>
            <ul>
                <li><Link onClick={() => menuLink()} to={"/" + link.toLowerCase() + "/" + t("servisler_router")}>{t("Servisler")}</Link></li>
                <li><Link onClick={() => menuLink()} to={"/" + link.toLowerCase() + "/" + t("projeler_router")}>{t("Projeler")}</Link></li>

                <li><Link onClick={() => menuLink()} to={"/" + link.toLowerCase() + "/" + t("hakkimizda_router")}>{t("Hakkımızda")}</Link></li>
                <li><Link onClick={() => menuLink()} to={"/" + link.toLowerCase() + "/" + t("references_router")}>{t("references")} </Link></li>
                <li><Link onClick={() => menuLink()} to={"/" + link.toLowerCase() + "/" + t("careers_router")}>{t("careers")} </Link></li>
                {/* <li><Link onClick={() => menuLink()} to={"/" + link.toLowerCase() + "/" + t("kariyer_router")}>{t("Danışmanlık")}</Link></li> */}
                <li><Link onClick={() => menuLink()} to={"/" + link.toLowerCase() + "/" + t("iletisim_router")}>{t("İletişim")}</Link></li>
            </ul>
            <div className="languages">
                <div className="active">
                    <img src={Icon} alt="Language" />
                    <p>{link}</p>
                </div>
                <ul>
                    <li>
                        {/* {
                            link === "TR" ?
                                <button onClick={() => changeLanguage('EN')}>EN</button> :
                                <button onClick={() => changeLanguage('PL')}>PL</button>
                        } */}
                        {/* <button onClick={() => changeLanguage('TR')}>TR</button> |  */}
                        <button onClick={() => changeLanguage('EN')}>EN</button> | 
                        <button onClick={() => changeLanguage('PL')}>PL</button>
                    </li>
                    {/* <li className="lineLanguage"></li> */}
                     {/* <li>
                        {
                            (i18n.language || window.localStorage.i18nextLng) === "TR" ?
                                <button onClick={() => changeLanguage('EN')}>EN</button> :
                                <button onClick={() => changeLanguage('PL')}>PL</button>
                        }
                    </li> */}
                </ul>
            </div>
        </div>

    );
}

export default withTranslation()(MobileMenu); 