import React from "react";
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import Services from "./services";
import Cta from "./cta"
import { withTranslation } from 'react-i18next';

import Main from "./main";

const Index = ({ t }) => {
    return (
        <>
            <MetaTags>
                <title>Navisio Tech. | {t('anaSayfaMetaTitle')}</title>
                <meta name="keywords" content={t('anaSayfaMetaKeyword')} />
                <meta name="description" content={t('anaSayfaMetaDescription')} />
                <meta name="author" content="navisio<info@navisio.net>" />
                <meta name="owner" content="Navisio" />
                <meta name="copyright" content="(c) 2020" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={t('anaSayfaMetaTitle')} />
                <meta property="og:description" content={t('anaSayfaMetaDescription')} />
                <meta name="twitter:card" content="summary" />
                <meta name="yandex-verification" content="5029a6989f669e45" />
            </MetaTags>
            <div>
                <Main />
                <Services />
                <Cta />
            </div>
        </>
    );
}

Index.propTypes = {
    t: PropTypes.func.isRequired,
}

export default withTranslation()(Index); 