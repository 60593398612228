import React from "react";
import MetaTags from "react-meta-tags";
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";
import i18n from "../i18n";
const referencesData = require("../references.json");

let language = i18n.language.toLocaleLowerCase();

function ReferenceCard(props) {
  const { id, refTitle, refDescription, refTechnologies, refImages, refLink } =
    props.reference;

  return (
    <div className="references-card">
      <div className="references-card--header">
        <div className="references-card--image_background" style={{  
            backgroundImage: `url("${refImages[0]}")`,
            backgroundPosition: 'top',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        }}></div>
        {/* <img
          className="references-card--image"
          src={refImages[0]}
          alt={refTitle[language]}
        /> */}
        <h3>{refTitle[language]}</h3>
      </div>

      <div className="references-card--body">
        <p>{refDescription[language]}</p>
        <p>
          <strong>
            {language === "pl" ? "Technologie:" : "Technologies:"}
          </strong>{" "}
          {refTechnologies[language]}
        </p>
        <a href={refLink[language]} target="_blank" rel="noopener noreferrer">
        {refLink[language]}
        </a>
      </div>
    </div>
  );
}

function ReferencesList(props) {
  return (
    <div className="references-list">
      {props.references.map((reference) => (
        <ReferenceCard
          key={reference.id}
          reference={reference}
          language={language}
        />
      ))}
    </div>
  );
}

function References({ props, t }) {
  return (
    <>
      <MetaTags>
        <title>Navisio Tech. | {t("referencesMetaTitle")}</title>
        <meta name="keywords" content={t("referencesMetaKeyword")} />
        <meta name="description" content={t("referencesMetaDescription")} />
        <meta name="author" content="navisio<info@navisio.eu>" />
        <meta name="owner" content="Navisio" />
        <meta name="copyright" content="(c) 2020" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={t("referencesMetaTitle")} />
        <meta
          property="og:description"
          content={t("referencesMetaDescription")}
        />
        <meta name="twitter:card" content="summary" />
      </MetaTags>
      <div className="references">
        <div className="row">
            <h1>{t("referencesHeader")}</h1>
          {parse(t("referencesHtml"))}
          <ReferencesList
            references={referencesData.references}
            language={language}
          />
        </div>
      </div>
    </>
  );
}

export default withTranslation()(References);
