
import React from 'react';
import MetaTags from 'react-meta-tags';
import { withTranslation } from 'react-i18next';
import parse from 'html-react-parser';

const Services = ({ t }) => {
    return (
        <>
            <MetaTags>
                <title>Navisio Tech. | {t('servislerMetaTitle')}</title>
                <meta name="keywords" content={t('servislerMetaKeyword')} />
                <meta name="description" content={t('servislerMetaDescription')} />
                <meta name="author" content="navisio<info@navisio.net>" />
                <meta name="owner" content="Navisio" />
                <meta name="copyright" content="(c) 2020" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={t('servislerMetaTitle')} />
                <meta property="og:description" content={t('servislerMetaDescription')} />
                <meta name="twitter:card" content="summary" />
            </MetaTags>
            <div className="servicesPage">
                <div className="row">
                    {parse(t('servislerHtml'))}
                </div>
            </div>
        </>
    );
};

export default withTranslation()(Services);
